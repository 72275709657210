import React from 'react';
import "./header.css";
import logo from './logo.png';

class Header extends React.Component {
    render() {
        return (
            <div align={"center"}>
                <div className={"LogoDiv"}>
                    <img className={"logo"} src={logo} alt={"logo"}/>
                </div>
                <div className={"HeaderText"}>
                    <h1 className={"Title"}>
                        William, LI Yuanhan
                    </h1>
                    <h2 className={"SubTitle"}>
                        Welcome!
                    </h2>
                    <h3 className={"SubTitle"}>
                        "This is a preview of the newer version of my personal website built with JavaScript."
                    </h3>
                    <a className={"Link"} href={"https://linkedin.williamlyh.com"} target={"_blank"}>
                        View Linkedin Page
                    </a>
                </div>
            </div>
        );
    }
}

export default Header;