import React from 'react';
import './body.css';

class Body extends React.Component {
    render() {
        return (
            <div className={'Body'}>
                <h2 className={"SubTitle-l"}>
                    Learn more about me as a:
                </h2>
                <div className={"HContainer"}>
                    <div className={"Box"}>
                        <h2 className={"Title"}>
                            Student
                        </h2>
                        <div className={"BoxContent"}>
                            <h3 className={"TextMain"}>
                                UC Berkeley
                            </h3>
                            <h4 className={"Text"}>
                                CS & Applied Math
                            </h4>
                            <h3 className={"TextMain"}>
                                Hefei No.8 HS
                            </h3>
                            <h4 className={"Text"}>
                                International Program
                            </h4>
                        </div>
                    </div>
                    <div className={'Box'}>
                        <h2 className={"Title"}>
                            Programmer
                        </h2>
                        <div className={"BoxContent"}>
                            <h3 className={"TextMain"}>
                                Skills
                            </h3>
                            <h4 className={"Text"}>
                                Python <br/> Java <br/> C++ <br/>
                                Android <br/> Scheme <br/> SQL <br/> Data Analysis
                            </h4>

                            <h3 className={"TextMain"}>
                                Projects
                            </h3>
                            <a className={"TextLink"} href={"https://oceanapp.org"} target={"_blank"}>
                                Ocean
                            </a>
                            <br/>
                            <a className={"TextLink"} href={"https://oceanapp.org"} target={"_blank"}>
                                - Android App
                            </a>
                            <h3 className={"TextMain"}></h3>
                            <a className={"TextLink"} href={"https://github.com/william-lyh/Emooc"}
                               target={"_blank"}>
                                Emoocs
                            </a>
                            <br/>
                            <a className={"TextLink"} href={"https://github.com/william-lyh/Emooc"}
                               target={"_blank"}>
                                - Mooc Api
                            </a>
                        </div>
                    </div>
                    <div className={'Box'}>
                        <h2 className={"Title"}>
                            Robotics Lover
                        </h2>
                        <div className={"BoxContent"}>
                            <h3 className={"TextMain"}>
                                Pioneers in Engineering
                            </h3>
                            <h4 className={"Text"}>
                                Member of Team Dawn
                            </h4>
                            <h3 className={"TextMain"}>
                                HS VEX Robotics Club
                            </h3>
                            <h4 className={"Text"}>
                                Chief Programmer <br/> & Engineer
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Body;