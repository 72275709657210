import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Header from "./header.js";
import Body from "./body";


class Home extends React.Component {
    render() {
        return(
            <div >
                <div className={"Header"}>
                    <Header />
                </div>
                <div className={"Body"}>
                    <Body />
                </div>
                <div className={"Note"}>
                    <h5>
                        Updated: 11/07/2019
                    </h5>
                </div>
            </div>
            );
    }
}

ReactDOM.render(<Home />,
    document.getElementById('root'))
